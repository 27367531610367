#content-contact {
  padding: 45px 0px;
  margin-top: 3%;
}
#content-contact h4 {
  color: #e1786b;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  line-height: 0;
  margin-bottom: 2%;
}
#content-contact .contact-content {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 70px;
  border-bottom: 1px solid #e2e8f0;
}
#content-contact h2 {
  color: #333333;
  font-size: 45px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
  margin-bottom: 2%;
}
#content-contact .contact-content .send-message-form {
  background: #f7fafc;
  padding: 30px;
  width: 544px;
  border-radius: 10px;
}
#content-contact .contact-content .contact-image img {
  padding-top: 50px;
  width: 480px;
}
#content-contact .contact-content .send-message-form input[type="text"],
[type="email"],
[type="number"] {
  border: 1px solid #e2e8f0;
  background: #fff;
  border-radius: 5px;
  line-height: 55px;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
}
#content-contact .invalid {
  border-color: #ea5455 !important;
}

#content-contact .contact-content .send-message-form textarea {
  border: 1px solid #e2e8f0;
  background: #fff;
  border-radius: 5px;
  line-height: 55px;
  width: 100%;
  padding-left: 20px;
}
#content-contact .contact-content .send-message-form input[type="submit"] {
  margin-top: 20px;
  width: 100%;
  border: none;
  background: #6bd4e1;
  border-radius: 30px;
  line-height: 45px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
#content-contact .contact-content .send-message-form ::placeholder {
  color: #afaaaa;
  padding: 0px 21px;
}

#content-contact .form-spinner{
  display: block;
  width: 40px;
  margin: 15px auto 0 auto;
}

@media (max-width: 767px) {
  #content-contact .contact-content {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #content-contact .contact-content .send-message-form {
    width: 100%;
  }
  #content-contact h4 {
    margin-bottom: 5%;
    text-align: center;
    line-height: normal;
  }
  #content-contact h2 {
    text-align: center;
  }
  #content-contact .contact-content .contact-image img {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width:1200px) {
  #content-contact .contact-content .contact-image img {
    width: 90% !important;
  }
  #content-contact .contact-content .send-message-form {
    width: 50% !important;
  }
}

