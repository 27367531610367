#content-resume {
  background-color: #f7fafc;
  padding: 45px 0px;
  margin-top: 3%;
}
#content-resume h4 {
  color: #e1786b;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  margin-bottom: 2%;
}
#content-resume h2 {
  color: #333333;
  font-size: 45px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
  margin-bottom: 2%;
}

#content-resume .content-resume-title {
  display: flex;
  justify-content: space-around;
}
#content-resume .content-resume-title h3 {
  color: #333333;
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 40px;
  line-height: 0;
  padding-top: 30px;
}
#content-resume .inside-content {
  display: flex;
  justify-content: space-between;
  padding: 13px 0px;
}

#content-resume .ic-box {
  background: #fff;
  border: 1px solid #e2e8f0;
  width: 525px;
  border-radius: 10px;
  padding: 15px 30px;
  margin-bottom: 25px;
}
#content-resume .info1 h3 {
  margin: 0px;
  color: #333333;
  font-size: 18px;
  padding: 25px 3px;
}
#content-resume .info1 img {
  padding: 15px 3px;
}
#content-resume .info1 h4 {
  margin: 0px;
  color: #333333;
  font-size: 14px;
  padding: 0px 6px;
}
#content-resume .info1 button {
  font-size: 14px;
  border: 1px solid #e1786b;
  background: none;
  line-height: 35px;
  color: #e1786b;
  width: 130px;
  cursor: auto;
}
#content-resume .info1 {
  display: flex;
  align-items: center;
}
#content-resume .inside-content p {
  font-size: 14px;
  color: #666666;
  padding-top: 20px;
}

#content-resume .ic-box:hover{
  transform: translateY(-9px);
  transition: 100ms;
}

@media (max-width: 1200px) {
  #content-resume h4 {
    margin-bottom: 30px;
    text-align: center;
  }
  #content-resume h2 {
    text-align: center;
  }
  #content-resume .inside-content {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #content-resume .ic-box {
    width: 100%;
  }
}
