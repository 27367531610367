.social-media-icons {
  display: flex;
  justify-content: space-evenly;
  width: 250px;
  margin: 50px auto 15px auto;
}
footer p {
  margin: 15px auto 0px auto;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 80px;
}