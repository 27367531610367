#content-portfolio {
  padding: 45px 0px;
  margin-top: 3%;
  background-color: #f7fafc;
}
#content-portfolio button {
  margin: 25px 0px !important;
}
#content-portfolio img {
  display: block;
}
#content-portfolio h4 {
  color: #e1786b;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  line-height: 0;
  margin-bottom: 2%;
}
#content-portfolio h2 {
  color: #333333;
  font-size: 45px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
  margin-bottom: 2%;
}
#content-portfolio .portfolio-buttons {
  display: flex;
  justify-content: space-between;
  width: 76%;
}
#content-portfolio .portfolio-buttons .portfolio-cta {
  border: 1px solid #6bd4e1;
  border-radius: 30px;
  background: none !important;
  color: #6bd4e1;
}
#content-portfolio .portfolio-first-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px 0px;
}
#content-portfolio .latest-projects-design {
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  padding: 25px;
  width: 330px;
  margin: 0 10px;
}

#content-portfolio .portfolio-first-row button {
  border: 1px solid #e1786b;
  border-radius: 30px;
  background: none !important;
  color: #e1786b;
  font-size: 14px;
  width: 100%;
  line-height: 35px;
  margin: 20px 0px !important;
}
#content-portfolio .portfolio-first-row img {
  width: 100%;
  height: 200px;
}
#content-portfolio .portfolio-first-row h3 {
  font-size: 18px;
  line-height: 27px;
  margin: 0px;
  color: #333333;
  font-weight: 500;
}

#content-portfolio .latest-projects-design:hover {
  transform: translateY(-9px);
  transition: 100ms;
}

@media (max-width: 767px) {
  #content-portfolio .portfolio-buttons {
    display: none;
  }
  #content-portfolio .portfolio-first-row {
    display: block;
  }
  #content-portfolio h4 {
    margin-bottom: 30px;
    text-align: center;
  }
  #content-portfolio h2 {
    text-align: center;
  }
  #content-portfolio .latest-projects-design {
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 25px;
    width: 330px;
    margin: 0 auto;
  }
}
