.intro-section {
  padding: 45px 0px;
  margin-top: 3%;
}

.intro-content h3 {
  font-size: 30px;
  font-weight: 600;
}

.intro-content h1 {
  font-size: 50px;
  font-weight: 700;
  margin-top: 3%;
}

.intro-content h1 span {
  color: #6bd4e1;
}

.intro-content p {
  font-size: 30px;
  margin-top: 3%;
}

.intro-content .getInTouchButton {
  font-size: 20px;
  margin-top: 4%;
  border-radius: 20px;
  color: white;
  background-color: #6bd4e1;
}

.intro-image {
  display: flex;
  justify-content: flex-end;
}

.intro-image img {
  width: 100%;
}

.desktopImage {
  display: block;
}
.mobileImage {
  display: none;
}

.intro-content {
  animation-duration: 3s;
  animation-name: comeFromTop;
  position: relative;
}

@keyframes comeFromTop {
  0% {
    top: 1000px;
  }
  25% {
    top: 800px;
  }
  50% {
    bottom: 600px;
  }
  100% {
    bottom: 0px;
  }
}

@media (max-width: 767px) {
  .intro-image {
    justify-content: center;
  }
  .intro-image img {
    width: 120%;
  }
  .intro-section {
    padding: 5px 0px 40px 0px;
    margin-top: 3%;
  }
  .desktopImage {
    display: none;
  }
  .mobileImage {
    display: block;
  }
  .intro-content .getInTouchButton {
    font-size: 20px;
    border-radius: 20px;
    color: white;
    display: block;
    margin: 8% auto 0 auto;
  }
  .intro-content {
    animation-duration: 3s;
    animation-name: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .intro-content h1 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 3%;
  }
}
