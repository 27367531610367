#content-features {
  background-color: #f7fafc;
  padding: 45px 0px;
  margin-top: 3%;
}
#content-features .features-first-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#content-features h4 {
  color: #e1786b;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  line-height: 0;
  margin-bottom: 2%;
}

#content-features h2 {
  color: #333333;
  font-size: 45px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
  margin-bottom: 2%;
}

#content-features .features-first-row {
  padding-top: 30px;
}

#content-features .skills-box {
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  padding: 30px;
  width: 320px;
  margin: 0px 10px;
}

#content-features img {
  width: 200px;
  height: 100px;
  margin-bottom: 15px;
}
#content-features h3 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
}
#content-features p {
  color: #666666;
  font-size: 15px;
  padding: 0px 0px;
}
#content-features button {
  margin: 17px 0px !important;
}

#content-features .feature:hover {
  transform: translateY(-9px);
  transition: 100ms;
}

button {
  background: #6bd4e1;
  border: none;
  width: 160px;
  line-height: 45px;
  border-radius: 23px;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
button a {
  color: #fff !important;
}

@media (max-width: 767px) {
  #content-features .features-first-row {
    display: block;
  }
  #content-features h4 {
    margin-bottom: 30px;
    text-align: center;
  }
  #content-features h2 {
    text-align: center;
  }
  #content-features .skills-box {
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 30px;
    width: 320px;
    margin: 0px auto;
  }
}
