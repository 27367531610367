header {
  padding: 50px 0 10px 0;
}

.profile-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
}

.profile-img img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 5px solid rgb(170 174 178);
}

.profile-detail {
  display: block;
  padding-left: 20px;
}

.profile-detail h2 {
  font-weight: 700;
}

.profile-detail p {
  color: rgb(225 120 107);
  font-size: large;
  font-weight: 600;
}

.header-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
}

.header-nav ul {
  list-style: none;
  margin-top: 80px;
}

.header-nav li {
  display: inline;
  margin: 0px 15px;
  font-size: x-large;
  font-weight: 500;
  cursor: pointer;
}

.header-nav a:hover {
  border: 5px solid #6bd4e1;
  padding: 5px 10px;
  border-radius: 5px;
}

.header-nav a {
  text-decoration: none;
  color: black;
}

.header-nav a:active,
.header-nav a.active {
  background: #6bd4e1;
  border-color: #292929;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.header-nav a:active:hover,
.header-nav a.active:hover {
  border: none;
}

.header-nav button:hover {
  transform: scale(1.1);
  color: white;
}

.mobile-nav {
  display: none;
}

.profile-img {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.profile-detail {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 200ms;
}

.header-nav-list li:nth-child(1) {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 400ms;
}

.header-nav-list li:nth-child(2) {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 600ms;
}

.header-nav-list li:nth-child(3) {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 800ms;
}

.header-nav-list li:nth-child(4) {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
}

.header-nav-list li:nth-child(5) {
  opacity: 0;
  animation-name: showAfterDelay1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1200ms;
}

.intro-image {
  animation-name: zoomIn;
  animation-duration: 2s;
  animation-delay: 1400ms;
  width: 0;
  animation-fill-mode: forwards;
}

@keyframes showAfterDelay1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@media (min-width: 1600px) {
  .header-nav li {
    margin: 0px 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-nav li {
    display: inline;
    margin: 0px 10px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .profile-img img {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    border: 5px solid rgb(170 174 178);
  }
}

@media (max-width: 767px) {
  .header-nav {
    display: none;
  }
  .mobile-nav {
    display: block;
    width: 100%;
    margin-top: 40px;
  }
  .mobile-nav-show {
    color: black;
    background-color: #6bd4e1;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    font-size: larger;
    width: 40px;
    height: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: larger;
  }
  .mobile-nav .header-nav-list {
    display: block;
    margin-top: 15px;
  }
  .mobile-nav ul {
    list-style: none;
    justify-content: center;
    background-color: #6bd4e1;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    border-radius: 5px;
    padding: 10px 0px 10px 0px !important;
  }

  .mobile-nav li {
    margin: 15px auto;
  }

  .NavLink {
    text-decoration: none;
  }
  .navLinkMobile {
    text-decoration: none;
    color: black;
  }
  .mobile-nav a:active,
  .mobile-nav a.active {
    background: #6bd4e1;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    border-bottom: 1px solid black;
    transform: scale(1.1);
  }

  .mobile-nav a:active:hover,
  .mobile-nav a.active:hover {
    border: none;
  }
  .profile-img {
    opacity: 1;
    animation-name: none;
  }
  .profile-detail {
    opacity: 1;
    animation-name: none;
  }

  .intro-image {
    animation-name: zoomIn;
    animation-duration: 2s;
    animation-delay: 400ms;
    width: 0;
    animation-fill-mode: forwards;
  }
  .header-nav-list li:nth-child(1) {
    opacity: 0;
    animation-name: showAfterDelay1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
  }

  .header-nav-list li:nth-child(2) {
    opacity: 0;
    animation-name: showAfterDelay1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
  }

  .header-nav-list li:nth-child(3) {
    opacity: 0;
    animation-name: showAfterDelay1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
  }

  .header-nav-list li:nth-child(4) {
    opacity: 0;
    animation-name: showAfterDelay1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 300ms;
  }

  .header-nav-list li:nth-child(5) {
    opacity: 0;
    animation-name: showAfterDelay1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 400ms;
  }
}
